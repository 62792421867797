<template>
  <section class="initial">
    <div class="initial--cover">
      <responsive-image src="initial/cover.png" />
    </div>
    <div class="initial--wrapper">
      <div class="initial--wrapper__title" v-html="$t('initial.title')" />
      <div @click="scrollToElement('.services')" class="initial--wrapper__arrow">
        <img src="/general/initial/arrow.png" />
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'initial',
  methods: {
        scrollToElement(selector) {
            const element = document.querySelector(selector);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            } else {
                console.error(`Element with selector '${selector}' not found.`);
            }
        }
    }
}
</script>

<style lang="scss">
.initial {
  @apply relative select-none;
  margin-bottom: -1px;

  &--cover {
    @apply overflow-hidden relative;
    picture {
      max-height: calc(100vh - var(--mobile-navbar-height));
      @screen lg {
        max-height: calc(100vh - var(--desktop-navbar-height));
      }
      img {
        @apply w-full h-full object-cover;
      }
    }
  }

  &--wrapper {
    @apply absolute bottom-0 left-0 w-full pb-16 grid gap-y-8 justify-items-center;

    &__title {
      @apply text-3xl font-bold text-white text-center px-8;

      span {
        @apply block text-5xl text-yellow pt-2;
        i {
          @apply hidden not-italic;
        }
      }
    }

  }
}

@screen lg {
  .initial {
    &--wrapper {
      @apply top-0 pb-0 pt-20 justify-items-start;
      margin-left: 12.5%;
      bottom: unset;
      max-width: 700px;

      &__title {
        @apply text-6xl text-left px-0;

        span {
          @apply text-6xl pt-0;
          i {
            @apply inline;
          }
        }
      }
      &__arrow {
        @apply hidden;
      }

    }
  }
}
</style>