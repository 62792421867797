<template>
    <footer class="footer">
        <div class="footer--logo">
            <responsive-image src="footer/logo.png" />
        </div>
        <div class="footer--infos">
            <div class="footer--infos__contact">
                <div class="footer--infos__contact--item">
                    <div class="footer--infos__contact--item__label">CNPJ</div>
                    <div class="footer--infos__contact--item__value">32.135.975/0001-13</div>
                </div>
                <div class="footer--infos__contact--item">
                    <div class="footer--infos__contact--item__label">Susep</div>
                    <div class="footer--infos__contact--item__value">202023000</div>
                </div>
            </div>
            <div class="footer--infos__divider" />
            <div class="footer--infos__copyright">
                copyright © {{ new Date().getFullYear() }} <span>Apoyo Corretora de Seguros LTDA</span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'CustomFooter'
}
</script>

<style lang="scss">
.footer {
    @apply py-8 bg-yellow grid gap-y-4;

    &--logo {
        @apply flex justify-center select-none;
    }

    &--infos {
        @apply grid gap-y-4 text-sm text-blue;

        &__contact {
            @apply flex justify-center gap-x-2;

            &--item {
                @apply flex gap-x-2;

                &__label {
                    @apply font-bold cursor-default select-none;
                }
            }
        }

        &__divider {
            @apply hidden;
        }

        &__copyright {
            @apply text-center select-none;

            span {
                @apply font-bold;
            }
        }
    }
}

@screen lg {
    .footer {
        @apply py-10 flex justify-center items-center gap-x-8;

        &--infos {
            @apply flex items-center gap-x-8 text-xs;

            &__contact {
                @apply flex justify-center gap-x-4;

                &--item {
                    @apply flex gap-x-2;

                    &__label {
                        @apply font-bold;
                    }
                }
            }

            &__divider {
                @apply h-9 bg-blue block;
                width: 0.75px;
            }
        }
    }
}
</style>