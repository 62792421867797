<template>
  <div class="layout">
    <transition name="fade" mode="out-in">
      <ContactsModal v-if="contacts" />
    </transition>
    <CustomMenu />
    <router-view />
    <CustomFooter />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import CustomMenu from './components/CustomMenu'
import CustomFooter from './components/CustomFooter'
import ContactsModal from './components/ContactsModal'

export default {
  name: 'App',
  components: {
    CustomMenu,
    CustomFooter,
    ContactsModal
  },
  computed: {
        ...mapGetters(['contacts'])
    }
}
</script>

<style lang="scss">
.layout {
  @apply grid min-h-screen;
  grid-template-rows: auto 1fr auto;
}
</style>
