<template>
  <section class="home">

    <InitialSection />
    <ServicesSection />
    <AboutSection />
    <EnterpriseSection />
    <ContactSection />

  </section>
</template>

<script>

import InitialSection from '@/views/InitialSection.vue'
import ServicesSection from '@/views/ServicesSection.vue'
import AboutSection from '@/views/AboutSection.vue'
import EnterpriseSection from '@/views/EnterpriseSection.vue'
import ContactSection from '@/views/ContactSection.vue'

export default {
  name: 'home',
  components: {
    InitialSection,
    ServicesSection,
    AboutSection,
    EnterpriseSection,
    ContactSection
  }
}
</script>

<style lang="scss"></style>