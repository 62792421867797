<template>
    <header>
        <div class="navbar">
            <div class="navbar--logo">
                <responsive-image src="navbar/logo.png" />
            </div>
            <div class="navbar--navigation">
                <div @click="scrollToElement('.services')" class="navbar--navigation__item">{{ $t('navbar.services') }}</div>
                <div @click="scrollToElement('.about')" class="navbar--navigation__item">{{ $t('navbar.about') }}</div>
                <div @click="open()" class="navbar--navigation__item">{{$t('navbar.schedule') }}</div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'CustomMenu',
    methods: {
        open() {
            this.$store.dispatch('setContacts', true);
        },
        scrollToElement(selector) {
            const element = document.querySelector(selector);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            } else {
                console.error(`Element with selector '${selector}' not found.`);
            }
        }
    }
}
</script>

<style lang="scss">
header {
    .navbar {
        @apply bg-yellow flex justify-center items-center;

        &--logo {
            @apply select-none;
        }

        &--navigation {
            @apply hidden;

            &__item {
                @apply select-none;
            }
        }
    }
}

@screen lg {
    header {
        .navbar {
            @apply justify-between;
            padding: 0 12.5%;

            &--navigation {
                @apply flex gap-x-4 items-center;

                &__item {
                    @apply text-blue font-bold cursor-pointer uppercase text-base relative;

                    &:not(:last-child)::after {
                        @apply bg-blue absolute block w-full left-1/2 transform -translate-x-1/2 opacity-0 transition-all duration-500;
                        content: '';
                        height: 1px;
                    }

                    &:hover {
                        &::after {
                            @apply opacity-100;
                        }
                    }

                    &:last-child {
                        @apply py-3 px-6 border-blue rounded-lg transition-all duration-500;
                        border: 1px solid;

                        &:hover {
                            @apply bg-blue text-yellow shadow-lg;
                        }
                    }
                }
            }
        }
    }
}
</style>