import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
 state: {
    contacts: false
 },
 getters: {
    contacts: state => state.contacts
 },
 mutations: {
    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    }
 },
 actions: {
    setContacts({ commit }, payload) {
      commit('SET_CONTACTS', payload);
    }
 }
});