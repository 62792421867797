<template>
  <section class="enterprise">
    <div :key="`enterprise-${item}`" v-for="item in items" class="enterprise--item">
      <div class="enterprise--item__icon">
        <img :src="`/general/enterprise/${item}.png`" />
      </div>
      <div class="enterprise--item__title">
        {{ $t(`enterprise.${item}.title`) }}
      </div>
      <div class="enterprise--item__text">
        {{ $t(`enterprise.${item}.text`) }}
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'enterprise',
  data() {
    return {
      items: ['mission', 'vision', 'values']
    }
  }
}
</script>

<style lang="scss">
.enterprise {
  @apply py-24 grid gap-y-10 select-none;

  &--item {
    @apply grid gap-y-4;

    &__icon {
      @apply flex justify-center;
    }

    &__title {
      @apply font-bold text-blue uppercase text-center;
    }

    &__text {
      @apply text-blue text-center px-14;
    }

    &:not(:last-child):after {
      content: '';
      @apply bg-yellow mt-10 px-4 w-11/12 mx-auto;
      height: 2px;
    }
  }
}

@screen lg {
  .enterprise {
    @apply flex justify-center gap-x-12;

    @screen 3xl {
      @apply gap-x-24;
    }

    &--item {
      @apply gap-y-3 relative pr-12 py-20;

      @screen 3xl {
        @apply pr-24;
      }

      &__title {
        @apply text-2xl;
      }

      &__text {
        @apply px-0;
        max-width: 300px;
      }

      &:not(:last-child):after {
        content: '';
        @apply mt-0 px-0 absolute right-0 h-full;
        width: 2px;
      }
    }
  }
}
</style>