<template>
  <section class="services">
    <div class="services--title">{{ $t('services.title') }}</div>
    <div class="services--subtitle">{{ $t('services.subtitle') }}</div>
    <div class="services--wrapper">
      <div class="services--wrapper__icon">
        <img src="/general/services/icon.png" />
      </div>
      <div class="services--wrapper__title">{{ $t('services.card.title') }}</div>
      <div class="services--wrapper__text">{{ $t('services.card.text') }}</div>
      <div class="services--wrapper__button">
        <button @click="open()">{{ $t('services.schedule') }}</button>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'services',
  methods: {
    open() {
      this.$store.dispatch('setContacts', true)
    }
  }
}
</script>

<style lang="scss">
.services {
  @apply bg-blue py-24 grid justify-items-center gap-y-4 select-none;
  margin-bottom: -1px;

  &--title {
    @apply text-3xl font-bold text-yellow uppercase text-center;
    max-width: 170px;
  }

  &--subtitle {
    @apply text-white text-center;
    max-width: 240px;
  }

  &--wrapper {
    @apply mt-6 p-8 rounded-2xl bg-white shadow-lg grid gap-y-4 justify-items-center mx-4;

    &__icon {}

    &__title {
      @apply font-bold text-blue uppercase text-center;
      max-width: 140px;
    }

    &__text {
      @apply text-blue text-center;
    }

    &__button {
      @apply flex justify-center;

      button {
        @apply py-3 px-6 border-blue rounded-lg transition-all duration-500 font-bold text-blue uppercase cursor-pointer;
        border: 1px solid;

        &:hover {
          @apply bg-blue text-yellow shadow-lg border-blue;
        }
      }
    }
  }
}

@screen lg {
  .services {
    @apply pt-6 pb-32;

    &--title {
      @apply text-4xl;
      max-width: unset;
    }

    &--subtitle {
      @apply text-2xl;
      max-width: unset;
    }

    &--wrapper {
      @apply px-32 gap-y-10 w-3/5 mx-auto;
      &__title {
        @apply text-2xl;
        max-width: unset;
      }
    }
  }
}
</style>