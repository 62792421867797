<template>
    <section class="contact">
        <div class="contact--cover">
            <responsive-image src="contact/cover.png" />
        </div>
        <div class="contact--title">{{ $t('contact.title') }}</div>
        <div class="contact--subtitle">{{ $t('contact.subtitle') }}</div>
        <div class="contact--infos">
            <a href="https://wa.me/5518996218214" target="_blank" class="contact--infos__item">(18) 99621-8214</a>
            <a href="https://wa.me/5518997308070" target="_blank" class="contact--infos__item">(18) 99730-8070</a>
            <div class="contact--infos__item">contato@apoyocorretoradeseguros.com.br</div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'contact',
}
</script>

<style lang="scss">
.contact {
    @apply relative py-14 px-6 grid gap-y-4;
    &--cover {
        @apply absolute inset-0 -z-10 select-none;
        img {
            @apply w-full h-full object-cover;
        }
    }
    &--title {
        @apply text-3xl font-bold text-yellow uppercase text-center select-none;
    }
    &--subtitle {
        @apply text-white text-center select-none;
    }
    &--infos {
        &__item {
            @apply block text-center text-white font-bold;
        }
    }
}
.contact {
    @apply relative py-14 px-0;
    &--title {
        @apply text-4xl;
    }
}
</style>