const infos = {
    title: 'Apoyo - Seguro de Vida em Grupo',
    description: 'A corretora é especializada em seguro de vida em grupo, atendendo a empresas de pequenos, médios e grandes portes  de todos os ramos de  atividades, sempre com a confiança de oferecer um plano que atenda às  expectativas  dos sócios proprietários e de seus  funcionários.',
    image: '/desktop/navbar/logo.png',
    url: 'https://www.apoyocorretoradeseguros.com.br/',
    keywords: 'apoyo, apoyo corretora de seguros,apoyo seguro de vida em grupo, seguro, seguro de vida, seguro de vida em grupo, seguro presidente epitácio, corretora de seguros, corretora de seguros de vida'
}

const metaInfo = {
    title: infos.title,
    meta: [
      { name: 'description', content: infos.description },
      { property: 'og:title', content: infos.title },
      { property: 'og:description', content: infos.description },
      { property: 'og:image', content: infos.image },
      { property: 'og:url', content: infos.url },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: infos.title },
      { name: 'twitter:description', content: infos.description },
      { name: 'twitter:image', content: infos.image },
    ],
    link: [
        { rel: 'canonical', href: infos.url },
        { rel: 'robots', href: 'index, follow' }
    ]
};

export default metaInfo;