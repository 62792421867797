<template>
  <section class="about">
    <div class="contact--cover">
      <responsive-image src="about/cover.png" />
    </div>
    <div class="about--title">{{ $t('about.title') }}</div>
    <div class="about--wrapper">
      <div class="about--wrapper__logo">
        <responsive-image src="about/logo.png" />
      </div>
      <div class="about--wrapper__infos">
        <div class="about--wrapper__infos--item">
          <div class="about--wrapper__infos--item__title">{{ $t('about.title') }}</div>
          <div class="about--wrapper__infos--item__text" v-html="$t('about.text')" />
        </div>
        <div class="about--wrapper__infos--item">
          <div class="about--wrapper__infos--item__title">{{ $t('about.our_managers.title') }}</div>
          <div class="about--wrapper__infos--item__text" v-html="$t('about.our_managers.text')" />
        </div>
        <div class="about--wrapper__infos--button">
          <button @click="open()">{{ $t('about.schedule') }}</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'about',
  methods: {
    open() {
      this.$store.dispatch('setContacts', true);
    }
  }
}
</script>

<style lang="scss">
.about {
  @apply relative pt-40 pb-16 px-6 grid gap-y-4 select-none;

  &--cover {
    @apply absolute inset-0 -z-10;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &--title {
    @apply text-3xl font-bold uppercase text-white text-center;
  }

  &--wrapper {
    &__logo {
      @apply flex justify-center;

      img {
        mix-blend-mode: lighten;
      }
    }

    &__infos {
      @apply grid gap-y-10;

      &--item {
        @apply grid gap-y-6;

        &:first-child {
          .about--wrapper__infos--item__title {
            @apply hidden;
          }
        }

        &__title {
          @apply text-3xl font-bold uppercase text-white text-center;
        }

        &__text {
          @apply text-white text-sm;

          p:not(:last-child) {
            @apply mb-6;
          }

          b {
            @apply font-bold;
          }
        }
      }

      &--button {
        @apply flex justify-center;

        button {
          @apply py-3 px-6 border-yellow rounded-lg transition-all duration-500 font-bold text-yellow uppercase cursor-pointer;
          border: 1px solid;

          &:hover {
            @apply bg-blue border-blue shadow-lg;
          }
        }
      }
    }
  }
}

@screen lg {
  .about {
    @apply py-32 px-0;

    &--title {
      @apply hidden;
    }

    &--wrapper {
      @apply w-90 mx-auto grid items-center gap-x-32;
      @screen xxl {
        @apply w-4/5;
      }
      @screen 3xl {
        @apply w-3/5;
      }
      grid-template-columns: 1fr auto;
      &__logo {
        width: max-content;
      }
      &__infos {
        @apply grid gap-y-10;

        &--item {
          @apply grid gap-y-6;

          &:first-child {
            .about--wrapper__infos--item__title {
              @apply block;
            }
          }

          &__title {
            @apply text-4xl text-left;
          }

          &__text {
            @apply text-base;
          }
        }

        &--button {
          @apply justify-start;
          button {
            @apply px-8;
          }
        }
      }
    }
  }
}
</style>