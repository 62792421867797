<template>
    <div class="contacts--modal">
        <div @click="close()" class="contacts--modal__mask"></div>
        <div class="contacts--modal__wrapper">
            <div class="contacts--modal__wrapper--header">
                <div @click="close()" class="contacts--modal__wrapper--header__close">
                    <img src="general/icons/close.png" alt="close" />
                </div>
            </div>
            <div class="contacts--modal__wrapper--list">
                <div class="contacts--modal__wrapper--list__item">
                    <span class="contacts--modal__wrapper--list__item--name">Kenji</span>
                    <button class="contacts--modal__wrapper--list__item--button">
                        <a class="phone" href="tel:18996218214">
                            <img src="general/icons/phone.png" alt="phone" />
                            <span>(18) 99621-8214</span>
                        </a>
                        <a class="whatsapp" href="https://wa.me/5518996218214" target="_blank">
                            <img src="general/icons/whatsapp.png" alt="whatsapp" />
                            Mandar mensagem
                        </a>
                    </button>
                </div>
                <div class="contacts--modal__wrapper--list__item">
                    <span class="contacts--modal__wrapper--list__item--name">Eduardo</span>
                    <button class="contacts--modal__wrapper--list__item--button">
                        <a class="phone" href="tel:18997308070">
                            <img src="general/icons/phone.png" alt="phone" />
                            <span>(18) 99730-8070</span>
                        </a>
                        <a class="whatsapp" href="https://wa.me/5518997308070" target="_blank">
                            <img src="general/icons/whatsapp.png" alt="whatsapp" />
                            Mandar mensagem
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>  
  export default {
    methods: {
        close() {
            this.$store.dispatch('setContacts', false)
        }
    }
  }
  </script>
  
  <style lang="scss">
  .contacts--modal {
    @apply fixed inset-0 z-50 flex items-center justify-center;

    &__mask {
      @apply absolute left-0 top-0 w-full h-full bg-black opacity-60;
    }
    &__wrapper {
        @apply grid gap-y-8 bg-white p-4 pb-16  z-20 rounded-lg shadow-md;
        &--header {
            @apply flex justify-end items-center select-none;
            &__close {
                @apply rounded-full cursor-pointer bg-red-600 text-white hover:bg-red-700 flex items-center justify-center transition-all duration-300;
                width: 20px;
                aspect-ratio: 1;
                img {
                    @apply invert;
                    height: 8px;
                }
            }
        }
        &--list {
            @apply grid gap-y-6 px-14;
            &__item {
                @apply grid gap-y-2 justify-center text-center;
                &--name {
                    @apply text-lg font-bold;
                }
                &--button {
                    @apply grid gap-y-4 items-center text-sm;
                    .phone {
                        @apply text-blue justify-center flex items-center gap-x-2;
                        span {
                            @apply underline;
                        }
                    }
                    .whatsapp {
                        border: 1px solid;
                        @apply flex items-center gap-x-2 py-3 px-2 border-blue rounded-lg transition-all duration-200 cursor-pointer text-blue font-semibold text-xs uppercase;
                    }
                }
            }
        }
    }
  }
  </style>
  